import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import Config from "config";

let envSettings = {
  fallbackLng: "de",
  debug: false,
  postProcess: false as false,
};

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init(
    {
      ...envSettings,
      missingKeyHandler: (lng, ns, key, fallbackValue) => {
        fetch(`${Config.apiUrl}/missing-key-handler.php`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lng,
            ns,
            key,
            fallbackValue,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status === 500) {
              throw Error(`${res.status} ${res.detail}`);
            }
            return res;
          })
          .then(() => {
            return true;
          })
          .catch(() => {
            return false;
          });
      },

      defaultNS: "common",
      ns: ["common", "onboarding", "standards", "countries"],
      fallbackNS: "common",
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      keySeparator: false, // so . is allowed in the strings

      react: {
        useSuspense: false,
        bindI18n: "languageChanged loaded",
        bindStore: "added removed",
        nsMode: "default",
      },
      backend: {
        loadPath:
          "https://casasoftch.github.io/casatranslations/fetched-translations/casaone/{{ns}}/{{lng}}.json",
      },
    },
    (err) => {
      if (err) {
        // eslint-disable-next-line no-console
        console.error("i18n init failed", err);
      }
      return false;
    }
  );

export default i18n;
