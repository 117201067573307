import { combineReducers } from "redux";

import { appReducer } from "redux/app";
import { gatewayReducer } from "redux/gateway";
import { authReducer } from "redux/auth";
import { eventTypesReducer } from "redux/eventTypes";

import { casageoApi, casaoneApi, casaoneApi2, casaoneCrmApi } from "../api";

import { rootReducer as mediaReducer } from "entities/media/store";
import { rootReducer as mediaChooserReducer } from "entities/mediaChooser/store";
import { rootReducer as propertyReducer } from "entities/property/store";
import { rootReducer as propertyMapReducer } from "entities/propertyMap/store";
import { rootReducer as unitReducer } from "entities/unit/store";
import { rootReducer as projectReducer } from "entities/project/store";
import { rootReducer as projectContractReducer } from "entities/projectContract/store";
import { rootReducer as dynamicPropertyReducer } from "entities/dynamicProperty/store";
import { rootReducers as publicationReducers } from "entities/publication/store";
import { rootReducer as propertyModalReducer } from "entities/propertyModal/store";
import { rootReducer as propertyContentMediaReducer } from "entities/propertyContentMedia/store";
import { rootReducer as propertyFeatureReducer } from "entities/propertyFeature/store";
import { rootReducer as propertyPublisherReducer } from "entities/propertyPublisher/store";
import { rootReducer as addressReducer } from "entities/address/store";

// mail settings entities
import { rootReducer as mainCompanyEmailSettingUserReducer } from "entities/companyEmailSettingUser/mainStore";
import { rootReducer as mainCompanyEmailSettingReducer } from "entities/companyEmailSetting/mainStore";
import { rootReducer as mainEmailSettingImapSmtpReducer } from "entities/emailSettingImapSmtp/mainStore";
import { rootReducer as mainEmailSettingNylasReducer } from "entities/emailSettingNylas/mainStore";
import { rootReducer as mainUserEmailSettingReducer } from "entities/userEmailSetting/mainStore";

// contact entity
import { rootReducer as mainContactReducer } from "entities/contact/mainStore";
import { rootReducer as employeeContactReducer } from "entities/contact/employeeStore";
import { rootReducer as birthdaysContactReducer } from "entities/contact/birthdaysStore";
import { rootReducer as matchingsContactReducer } from "entities/contact/matchingsStore";
import { rootReducer as dynamicContactReducer } from "entities/contact/dynamicStore";

import { rootReducer as userSignatureReducer } from "entities/userSignature/store";
import { rootReducer as caCompanyCoordReducer } from "entities/caCompanyCoord/store";
import { rootReducer as caContractReducer } from "entities/caContract/store";
// event entity
import { rootReducer as eventReducer } from "entities/event/depreceatedStore";
import { rootReducer as dynamicEventReducer } from "entities/event/dynamicStore";
import { rootReducer as mainMessageReducer } from "entities/event/mainMessageStore";
import { rootReducer as quickMessageReducer } from "entities/event/quickMessageStore";
import { rootReducer as mainReminderReducer } from "entities/event/mainReminderStore";
import { rootReducer as quickReminderReducer } from "entities/event/quickReminderStore";
import { rootReducer as mainTaskReducer } from "entities/event/mainTaskStore";
import { rootReducer as quickTaskReducer } from "entities/event/quickTaskStore";
import { rootReducer as quickAlertReducer } from "entities/event/quickAlertStore";
import { rootReducer as notificationReducer } from "entities/event/notificationStore";
import { rootReducer as appointmentReducer } from "entities/appointment/store";

// wp faq entities
import { rootReducer as faqReducer } from "entities/zendeskFaq/store";

import { rootReducer as caAddressReducer } from "entities/caAddress/store";
import { rootReducer as caProductsReducer } from "entities/caProduct/store";
import { rootReducer as cgProvidersReducer } from "entities/cgProvider/store";
import { rootReducer as cgPublishersReducer } from "entities/cgPublisher/store";
import { rootReducer as cgProviderPublisherSettingsReducer } from "entities/cgProviderPublisherSettings/store";
import { rootReducer as cgCompanyPublisherSettingsReducer } from "entities/cgCompanyPublisherSettings/store";
import { rootReducer as propertyPublisherChannelsReducer } from "entities/propertyPublisherChannel/store";
import { rootReducer as contactSearchprofilesReducer } from "entities/contactSearchprofile/store";
import { rootReducer as templateReducer } from "entities/template/store";
import { rootReducer as subsidiaryReducer } from "entities/subsidiary/store";
import { rootReducer as propertyContentDescriptionReducer } from "entities/propertyContentDescription/store";
import { rootReducer as propertyContentReducer } from "entities/propertyContent/store";
import { rootReducer as propertyReportReducer } from "entities/propertyReport/store";
import { rootReducer as propertyMatchReducer } from "entities/propertyMatch/store";
import { rootReducer as propertyContactReducer } from "entities/propertyContact/store";
import { rootReducer as dynamicPropertyContactReducer } from "entities/dynamicPropertyContact/store";
import { rootReducer as contactMediaReducer } from "entities/contactMedia/store";
import { rootReducer as contactMortgageReducer } from "entities/contactMortgage/store";
import { rootReducer as mainContactRelationReducer } from "entities/contactRelation/mainStore";
import { rootReducer as eventMediaReducer } from "entities/eventMedia/store";
import { rootReducer as serviceRequestReducer } from "entities/serviceRequest/store";
import { rootReducer as serviceCreditReducer } from "entities/serviceCredit/store";
import { rootReducer as propertyReportViewerReducer } from "entities/propertyReportViewer/store";
import { rootReducer as propertyReportCommentReducer } from "entities/propertyReportComment/store";
import { rootReducer as propertyIntegratedOfferReducer } from "entities/propertyIntegratedOffer/store";
import { rootReducer as contactWithAccesskeyReducer } from "entities/contactWithAccesskey/store";
import { rootReducer as contactSearchprofileWithAccesskeyReducer } from "entities/contactSearchprofileWithAccesskey/store";
import { rootReducer as addressWithAccesskeyReducer } from "entities/addressWithAccesskey/store";
import { rootReducer as placeReducer } from "entities/place/store";
import { rootReducer as searchprofileTemplateReducer } from "entities/searchprofileTemplate/store";
import { rootReducer as eventAutoreplyReducer } from "entities/eventAutoreply/store";
import { rootReducer as CaInvoiceReducer } from "entities/caInvoices/store";
import { rootReducer as caInvoiceCrmReducer } from "entities/caInvoicesCrm/store";

const combinedReducers = combineReducers({
  [casaoneApi.reducerPath]: casaoneApi.reducer,
  [casaoneApi2.reducerPath]: casaoneApi2.reducer,
  [casageoApi.reducerPath]: casageoApi.reducer,
  [casaoneCrmApi.reducerPath]: casaoneCrmApi.reducer,

  addresses: addressReducer,
  addressesWithAccesskey: addressWithAccesskeyReducer,
  app: appReducer,
  auth: authReducer,
  caAddresses: caAddressReducer,
  caCompanyCoords: caCompanyCoordReducer,
  caContracts: caContractReducer,
  caProducts: caProductsReducer,
  cgProviderPublisherSettings: cgProviderPublisherSettingsReducer,
  cgCompanyPublisherSettings: cgCompanyPublisherSettingsReducer,
  cgProviders: cgProvidersReducer,
  cgPublishers: cgPublishersReducer,
  contactMedias: contactMediaReducer,
  contactMortgages: contactMortgageReducer,
  mainContactRelations: mainContactRelationReducer,
  // mail settings entities
  mainCompanyEmailSettingUsers: mainCompanyEmailSettingUserReducer,
  mainCompanyEmailSettings: mainCompanyEmailSettingReducer,
  mainEmailSettingsImapSmtp: mainEmailSettingImapSmtpReducer,
  mainEmailSettingsNylas: mainEmailSettingNylasReducer,
  mainUserEmailSettings: mainUserEmailSettingReducer,
  // contact entity
  mainContacts: mainContactReducer,
  employeeContacts: employeeContactReducer,
  birthdaysContacts: birthdaysContactReducer,
  matchingsContacts: matchingsContactReducer,
  dynamicContacts: dynamicContactReducer,

  contactSearchprofiles: contactSearchprofilesReducer,
  contactSearchprofilesWithAccesskey: contactSearchprofileWithAccesskeyReducer,
  contactsWithAccesskey: contactWithAccesskeyReducer,
  eventMedias: eventMediaReducer,
  // event entity
  events: eventReducer,
  dynamicEvents: dynamicEventReducer,
  mainMessages: mainMessageReducer,
  quickMessages: quickMessageReducer,
  mainReminders: mainReminderReducer,
  quickReminders: quickReminderReducer,
  mainTasks: mainTaskReducer,
  quickTasks: quickTaskReducer,
  quickAlerts: quickAlertReducer,
  notifications: notificationReducer,
  appointments: appointmentReducer,

  // zendesk faq entitie
  faqs: faqReducer,

  eventTypes: eventTypesReducer,
  gateway: gatewayReducer,
  medias: mediaReducer,
  mediaChoosers: mediaChooserReducer,
  places: placeReducer,
  projectContracts: projectContractReducer,
  projects: projectReducer,
  properties: propertyReducer,
  propertiesMap: propertyMapReducer,
  propertiesModal: propertyModalReducer,
  propertyContacts: propertyContactReducer,
  dynamicPropertyContacts: dynamicPropertyContactReducer,
  propertyContentDescriptions: propertyContentDescriptionReducer,
  propertyContentMedias: propertyContentMediaReducer,
  propertyContents: propertyContentReducer,
  propertyFeatures: propertyFeatureReducer,
  propertyIntegratedOffers: propertyIntegratedOfferReducer,
  propertyMatches: propertyMatchReducer,
  propertyPublisher: propertyPublisherReducer,
  propertyPublisherChannels: propertyPublisherChannelsReducer,
  propertyReportComments: propertyReportCommentReducer,
  propertyReports: propertyReportReducer,
  propertyReportViewers: propertyReportViewerReducer,
  dynamicProperties: dynamicPropertyReducer,
  singlePublications: publicationReducers.singlePublicationReducer,
  projectPublications: publicationReducers.projectPublicationReducer,
  serviceCredits: serviceCreditReducer,
  serviceRequests: serviceRequestReducer,
  subsidiaries: subsidiaryReducer,
  templates: templateReducer,
  units: unitReducer,
  userSignatures: userSignatureReducer,
  searchprofileTemplates: searchprofileTemplateReducer,
  // eventPhases: eventPhaseReducer,
  eventAutoreplies: eventAutoreplyReducer,
  caInvoices: CaInvoiceReducer,
  caInvoicesCrm: caInvoiceCrmReducer,
});

export default combinedReducers;
