import { useTranslation } from "react-i18next";

const Warning = () => {
  const { t } = useTranslation();

  return (
    <div>
      {t("csmessage-119_1")}
      <span className="tw-font-semibold"> {t("csmessage-119_5")} </span>
      <span
        className="tw-underline tw-cursor-pointer"
        onClick={() => window.open(t("csmessage-119_2"), "_blank")}
      >
        {t("csmessage-119_3")}
      </span>
      <span className="tw-font-semibold"> {t("csmessage-119_4")}</span>
    </div>
  );
};

export default Warning;
